import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import { DialogContent, TextField } from "@mui/material";
import { CheckButton, GrayButton } from "../style";
import { useEffect } from "react";

const emails = ["username@gmail.com", "user02@gmail.com"];

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const newSearch = () =>{
    props.onClose()
    props.resetImage()

  }



  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Reverse image search</DialogTitle>
      <DialogContent>
        Om tot een zo goed mogelijk resultaat te komen, is het belangrijk dat je
        een zo authentiek mogelijke versie van je foto gebruikt. Hoe meer mensen
        de foto gekopieerd of opgeslagen hebben, hoe groter de kans dat iemand
        al eens een aanpassing gedaan heeft. Ga daarom via Google op zoek naar
        de oudste versie van je foto.<br/><br/>

        <a
          href={`https://images.google.com/searchbyimage?image_url=${
            props.searchUrl
          }&image_content=&filename=&hl=nl-BE`}
          target="_blank"
        >
          Andere versies van mijn afbeelding bekijken via Google.
        </a>
        {/* <TextField style={{width:'100%',marginBottom:'20px',marginTop:'20px'}} defaultValue={props.searchUrl} onChange={(e)=>props.setSearchUrl(e.target.value)}/> */}
        <div style={{display:'flex',marginTop:'20px'}}>
        <CheckButton style={{marginRight:'20px'}} onClick={()=>props.confirm()}>Verder met de huidige afbeelding</CheckButton>
        <CheckButton onClick={()=>{props.resetImage();props.onClose()}}>Een nieuwe afbeelding uploaden</CheckButton></div>

      </DialogContent>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function ReverseImgSearchComponent(props) {
  const [open, setOpen] = React.useState(false);
  const [displayErr,setDisplayErr]=React.useState(false)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  useEffect(() => {

    if (props.open){
        setOpen(true)
    }
   

  }, [props]);

  return (
    <>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        searchUrl={props.searchUrl}
        uploadImage={props.uploadImage}
        setSearchUrl={props.setSearchUrl}
        resetImage={props.resetImage}
        confirm={props.confirm}
      />
      {/* {displayErr && <p>Gelieve eerst een link in te geven.</p>} */}
    </>
  );
}
