import { Card, CardContent } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
export default function CheckHistory(props) {
  return props.checkHistory.filter((check)=>check.conclusion!==undefined).map((previousCheck, index) => {
    return (

      
      <Card
        style={{
          width: "50vw",
          margin: "auto",
          marginTop: "1vh",
          cursor: "pointer"
        }}
        onClick={() => window.open("/final/" + previousCheck.id, "_blank")}
      >
        <CardContent>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={previousCheck.imageChecked}
                style={{
                  width: "7vw",
                  borderRadius: "5px",
                  marginRight: "5px",
                }}
              />

              {previousCheck.conclusion !== undefined && (
                <>
                  <p>
                    {previousCheck.conclusion.imgConclusion ===
                      "not-manipulated" && (
                      <span style={{ color: "green" }}>
                        Deze afbeelding is wellicht niet gemanipuleerd
                      </span>
                    )}
                    {previousCheck.conclusion.imgConclusion ===
                      "manipulated" && (
                      <span style={{ color: "red" }}>
                        Deze afbeelding is wellicht gemanipuleerd
                      </span>
                    )}
                    {previousCheck.conclusion.imgConclusion === "unclear" && (
                      <span style={{ color: "gray" }}>
                        Er is onzekerheid over de echtheid van deze afbeelding
                      </span>
                    )}
                    <br />
                    Analyse op{" "}
                    {new Date(
                      parseInt(previousCheck.id.toString())
                    ).toLocaleDateString()}{" "}
                    {new Date(
                      parseInt(previousCheck.id.toString())
                    ).toLocaleTimeString()}
                  </p>
                </>
              )}
            </div>
            <OpenInNewIcon />
          </div>
        </CardContent>
      </Card>
    );
  });
}
