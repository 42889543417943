import * as React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { ColoredDot, QontoConnector } from "../style";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Grid } from "@mui/material";
import ElaAndGhost from "../textualExplanations/ElaAndGhost";
import BlockingArtifacts from "../textualExplanations/BlockingArtifactsView";
import DeepLearning from "../textualExplanations/DeepLearningView";
import NoiseDetection from "../textualExplanations/NoiseView";
import DoubleJpegQuantization from "../textualExplanations/DQ";
import { useNavigate } from "react-router-dom";
import ImageRow from "../components/ImageComponent";
import db from "../firebaseconfig";
import { doc, setDoc, addDoc } from "firebase/firestore";
import NWSLabLogo from "../components/NWSLabLogo";

function CustomStep(props) {
  return (
    <Step key={props.label} {...props.stepProps}>
      <StepLabel StepIconComponent={() => <ColoredDot color={props.color} />} />
    </Step>
  );
}

export default function ImageAnalysis(props) {
  let initContent = [
    {
      name: "Onderbrekingen in het raster",
      text: <BlockingArtifacts />,
      images: props.report["blockingArtifacts"],
      suspicious: "gray",
    },
    {
      name: "Verschil in berekeningen bij recompressie",
      text: <ElaAndGhost />,
      images: props.report["ela"],
      suspicious: "gray",
    },
    {
      name: "Verschil in opbouw afbeelding",

      text: <DoubleJpegQuantization />,
      images: props.report["dq"],
      suspicious: "gray",
    },
    {
      name: "Ruisdetectie",
      text: <NoiseDetection />,
      images: props.report["noise"],
      suspicious: "gray",
    },
    {
      name: "Artificiële intelligentie",
      text: <DeepLearning />,
      images: props.report["deepLearning"],
      suspicious: "gray",
    },
  ];

  const [gridContent, setGridContent] = React.useState(initContent);
  const [activeStep, setActiveStep] = React.useState(0);
  let navigate = useNavigate();

  const saveToFirebase = async (suspectedMaps) => {
    let ms = new Date().valueOf();
    let userInput = props.getUserInput();
    let reportId = ms.toString();
    let metadata = props.report.metadataReport?.metadataObjectReport.values;
    metadata = metadata[metadata.length - 1];
    metadata = metadata.values;
    const gpsReport = props.report.metadataReport?.gpsReport;

    await setDoc(doc(db, "checked-images", reportId), {
      imageChecked: props.searchUrl,
      suspectedMaps: suspectedMaps,
      userInput: userInput,
      metadata: metadata,
      gpsReport: gpsReport,
    });
    return reportId;
  };

  const handleNext = async () => {
    let newStep = activeStep + 1;
    if (newStep === gridContent.length) {
      let finalMaps = [];
      gridContent.forEach((heatmapCluster) =>
        finalMaps.push({
          name: heatmapCluster["name"],
          images: [...heatmapCluster["images"]],
          suspicious: heatmapCluster["suspicious"],
        })
      );
      let reportId = await saveToFirebase(finalMaps);
      navigate("/final/" + reportId, {
        state: {
          report: finalMaps,
        },
      });
    } else {
      setActiveStep(newStep);
    }
  };

  const handleBack = () => {
    setSuspicious("gray");
    let newStep = activeStep - 1;
    setActiveStep(newStep);
  };

  const setSuspicious = (color) => {
    gridContent[activeStep]["suspicious"] = color;
    setGridContent(gridContent);
    handleNext();
  };

  const stepper = (
    <div style={{ display: "flex" }}>
      <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
        <NavigateBeforeIcon />
      </Button>
      <Stepper activeStep={activeStep} connector={<QontoConnector />}>
        {gridContent.map((label, index) => {
          const stepProps = {};
          return (
            <CustomStep
              stepProps={stepProps}
              label={label}
              key={index}
              color={label["suspicious"]}
            />
          );
        })}
      </Stepper>
      <Button onClick={handleNext}>
        <NavigateNextIcon />
      </Button>
    </div>
  );

  return (
    <>
      <Grid item xs={4} sm={8} md={4} lg={6} style={{ height: "0vh" }} />
      <Grid item xs={4} sm={8} md={4} lg={12}>
        {gridContent[activeStep]["text"]}
      </Grid>
      <ImageRow
        firstCell={props.searchUrl}
        secondCell={gridContent[activeStep]["images"]}
        setSuspicious={(e) => setSuspicious(e)}
        stepper={stepper}
      />
           
    </>
  );
}
