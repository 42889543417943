export default function NoiseDetection() {
  return (
    <>
      <h2>Ruisdetectie</h2>
      <p>
        Ruis ontstaat door kleine imperfecties in je camera en door
        onregelmatigheden in de reflectie van licht. Dit soort{" "}
        <b>
          willekeurige patronen kan je niet nabootsen als je een afbeelding
          manipuleert
        </b>
      </p>
    </>
  );
}
