import "./App.css";
import Home from "./views/HomeView";
import NWSLabToolbar from "./components/NWSLabToolBar";
import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { DetailView } from "./views/DetailView";
import GeneralInfo from "./views/InfoView";
import HumanAnalysis from "./views/HumanAnalysisView";
import { useState } from "react";
import { Grid } from "@mui/material";
import FinalReport from "./views/FinalReportView";
import ImageAnalysis from "./views/ImageAnalysisView";
import NWSLabLogo from "./components/NWSLabLogo";

function App() {
  const [report, setReport] = useState({});
  const [searchUrl, setSearchUrl] = useState("");
  let [detail, setDetail] = useState(false);
  let [photoshop, setPhotoshop] = useState(false);
  let [hypothesis, setHypothesis] = useState("");

  const getUserInput = () => {
    return { detail, photoshop, hypothesis };
  };

  return (
    <div>
      <NWSLabToolbar />
      <Grid
        container
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 4, sm: 8, md: 4, lg: 18 }}
        style={{ width: "80vw", margin: "auto", marginTop: "5vh" }}
      >
        <Routes>
          <Route
            path="/"
            element={<Home setReport={setReport} setSearchUrl={setSearchUrl} />}
          />
          <Route
            path="/human-analysis"
            element={
              <HumanAnalysis
                searchUrl={searchUrl}
                setHypothesis={setHypothesis}
              />
            }
          />
          <Route
            path="/detail"
            element={
              <DetailView
                setDetail={setDetail}
                setPhotoshop={setPhotoshop}
                searchUrl={searchUrl}
              />
            }
          />
          <Route path="/info" element={<GeneralInfo searchUrl={searchUrl} />} />
          <Route
            path="/analysis"
            element={
              <ImageAnalysis
                report={report}
                searchUrl={searchUrl}
                getUserInput={getUserInput}
              />
            }
          />

          <Route
            path="/final/:reportId"
            element={
              <FinalReport
                searchUrl={searchUrl}
                getUserInput={getUserInput}
                report={report}
              />
            }
          />
        </Routes>
    
      </Grid>

    </div>
  );
}

export default App;
