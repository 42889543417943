import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function HeatMapReport(props) {
  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h3>{props.title}</h3>
        </AccordionSummary>
        <AccordionDetails>
        {Object.keys(props.heatmaps).map((heatmap, index) => {
            return (
              <div key={index}>
                <h4 style={{ color: "gray" }}>
                  {props.heatmaps[heatmap]["name"]}
                </h4>
                <div style={{ width: "100%", display: "flex" }}>
                  {props.heatmaps[heatmap]["images"].map((image, i) => {
                    return (
                      <img
                        src={image}
                        key={i}
                        alt="heatmap"
                        style={{
                          borderRadius: "5px",
                          width: "12vw",
                          margin: "5px",
                        }}
                      />
                    );
                  })}{" "}
                </div>
              </div>
            );
          })}
        </AccordionDetails>
      </Accordion>
  );
}
