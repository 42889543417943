export default function NWSLabLogo(props) {
  let marginLeft = props.noMargin
  return (
    <img
      alt="vrt-nwslab"
      src={process.env.PUBLIC_URL + "/logo-vrt_NWSlab-pos-rgb.png"}
      style={{
        width: "90px",
        marginLeft: props.noMargin ? 0 :'calc(50% - 45px)',
        marginTop:'15px'
      }}
    />
  );
}
