import { CheckButton, UploadContainer } from "./style";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import ReverseImgSearchComponent from "./components/ReverseImgSearchComponent";

function formatReport(report) {
  console.log(report);
  const mantranetMap = report["mantranet_report"]["map"];
  const splicebusterMap = report["splicebuster_report"]["map"];
  const cmfdMap = report["cmfd_report"]["map"];
  const dctMap = report["dct_report"]["map"];
  const elaMap = report["ela_report"]["map"];
  const ghostMap = report["ghost_report"]["maps"][1];
  const waveletMap = report["wavelet_report"]["map"];
  const medianMap = report["median_report"]["map"];
  const cagiNormalReport = report["cagi_report"]["cagiNormalReport"]["map"];
  const cagiInversedReport = report["cagi_report"]["cagiInversedReport"]["map"];
  const metadataReport = report["metadata_report"];

  let mappedReport = {
    blockingArtifacts: [
      report["blk_report"]["map"],
      cagiNormalReport,
      cagiInversedReport,
    ],
    dq: [report["adq1_report"]["map"], dctMap],
    deepLearning: [splicebusterMap, cmfdMap],
    ela: [elaMap, ghostMap],
    noise: [waveletMap, medianMap],
    metadataReport: metadataReport,
  };
  return mappedReport;
}

function UploadComponent(props) {
  let navigate = useNavigate();
  let [searchUrl, setSearchUrl] = useState("");
  let [loading, setLoading] = useState(false);
  let [err, setErr] = useState("");
  let [name, setName] = useState("");
  let [openDialog, setOpenDialog] = useState(false);

  const handleImage = async ({ target }) => {
    setLoading(true);

    let formData = new FormData();
    formData.append("file", target.files[0]);
    let name = target.files[0].name;
    setName(name);
    let res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/uploadImg`,
      formData,
      {
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=---------------------------257198717113635709454180388714",
        },
      }
    );
    setSearchUrl(JSON.parse(JSON.stringify(res.data))["location"]);
    // await uploadImage();
    setOpenDialog(true);
    setLoading(false);
  };

  const confirm = async () => {
    setOpenDialog(false);
    await uploadImage();
  };

  const resetImage = () => {
    setSearchUrl("");
    setName("");
    props.setReport({});
    setOpenDialog(false);
  };

  const getStatus = async (id) => {
    let processingInfo = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_BACKEND_URL}/getJobStatus?jobId=${id}`,
    });
    processingInfo = processingInfo.data.status;
    return processingInfo;
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const processReport = async (processingInfo) => {
    let report = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_BACKEND_URL}/getReport?itemHash=${processingInfo.itemHash}`,
    });
    report = JSON.parse(JSON.stringify(report.data.report));
    let formatted = formatReport(report);
    props.setReport(formatted);
    props.setSearchUrl(searchUrl);
    navigate("/human-analysis");
    setLoading(false);
  }

  const uploadImage = async () => {
    setLoading(true);
    setErr("");
    try {
      setErr("");

      let res = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BACKEND_URL}/checkImage?url=${searchUrl}`,
      });

      let id = res.data.id;

      let processingInfo = await getStatus(id);
      let i = 0;

      while (i < 15 && processingInfo.status !== "COMPLETED") {
        await delay(5000);
        processingInfo = await getStatus(id);
        console.log(processingInfo.status, "here");
        if (processingInfo.status == "COMPLETED") {
          break;
        }
        i = i + 1;
      }

      if (processingInfo.status === "COMPLETED") {
        processReport(processingInfo)
      } else {
        console.log(err);
        setErr(
          "Er ging iets mis bij het ophalen van je resultaten. Probeer het even opnieuw."
        );
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setErr(
        "Er ging iets mis bij het ophalen van je resultaten. Probeer het even opnieuw."
      );
      setLoading(false);
    }
  };

  return (
    <>
      <UploadContainer>
        {/* <UploadField
          placeholder="Plak hier de link naar de foto die je op manipulaties wil checken."
          onChange={handleChange}
        /> */}
        {loading ? (
          <CircularProgress />
        ) : (
          //   <CheckButton onClick={async () => uploadImage()}>Checken</CheckButton>
          <>
            <input
              accept="image/*"
              onChange={handleImage}
              id="upload-img"
              type="file"
              hidden
            />
            <label htmlFor="upload-img">
              <p style={{ color: "black" }} component="span">
                <>
                  {name === "" ? (
                    <>Een afbeelding checken... </>
                  ) : (
                    <> {name} </>
                  )}
                </>
                <CheckButton component="span">Uploaden</CheckButton>
              </p>
            </label>
            <ReverseImgSearchComponent
              searchUrl={searchUrl}
              uploadImage={uploadImage}
              setSearchUrl={setSearchUrl}
              open={openDialog}
              confirm={confirm}
              resetImage={resetImage}
            />
          </>
        )}
      </UploadContainer>
      {err !== "" && (
        <p
          style={{
            margin: "auto",
            marginTop: "10px",
            width: "50vw",
            color: "red",
          }}
        >
          {err}
        </p>
      )}
      {/* <FindUrlComponent /> */}
    </>
  );
}

export default UploadComponent;
