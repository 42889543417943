import { TextareaAutosize } from "@mui/material";
import { CenteredFlexContainer, CheckButton, FooterContainer, LogoContainer } from "../style";
import { useNavigate } from "react-router-dom";
import ImageTextRow from "../components/ImageTextComponent";
import NWSLabLogo from "../components/NWSLabLogo";
import FooterComponent from "../components/FooterComponent";

function HumanAnalysis(props) {
  let navigate = useNavigate();

  return (
    <>
    <ImageTextRow
      style={{ position: "relative" }}
      firstCell={props.searchUrl}
      secondCell={
        <>
          <p>
            Een digitale analyse van een foto is niet altijd foutloos. Daarom is
            het belangrijk om je eigen hypothese scherp te stellen voor je met
            technologie aan de slag gaat. Wat heeft jou ertoe aangezet deze foto
            te onderzoeken?
          </p>
          <TextareaAutosize
            onChange={(e) => props.setHypothesis(e.target.value)}
            minRows={3}
            style={{ width: "100%", marginBottom: "5px" }}
          />
          <CheckButton onClick={() => navigate("/detail")}>
            Volgende
          </CheckButton>
     
        </>
      }
    />
    <div
    style={{
      position: "fixed",
      bottom: "0px",
      backgroundColor: "white",
      width: "100vw",
      marginLeft:'-10vw'
    }}
  >
    <NWSLabLogo />
  </div>
    </>
  );
}

export default HumanAnalysis;
