import {
  CenteredFlexContainer,
  FalseButton,
  FooterContainer,
  TrueButton,
} from "../style";
import NWSLabLogo from "./NWSLabLogo";

export default function FooterComponent(props) {
  return (
    <FooterContainer>
      <p>Op basis van bovenstaande analyse besluit ik:</p>
      <CenteredFlexContainer>
        <TrueButton
          variant="contained"
          onClick={() => props.setSuspicious("green")}
        >
          Waarschijnlijk echt
        </TrueButton>
        <FalseButton
          variant="contained"
          onClick={() => {
            props.setSuspicious("red");
          }}
        >
          Waarschijnlijk vals
        </FalseButton>
      </CenteredFlexContainer>
      <CenteredFlexContainer style={{ marginTop: "5px", marginBottom: "30px" }}>
        {props.stepper}
      </CenteredFlexContainer>
            <NWSLabLogo noMargin={true} />
    </FooterContainer>
  );
}
