import UploadComponent from "../UploadComponent";
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import db from "../firebaseconfig";
import { Grid, ImageList, ImageListItem } from "@mui/material";
import PreviousChecks from "../components/PreviousChecksComponent";
import CheckHistory from "../components/PreviousChecksComponent";
import NWSLabLogo from "../components/NWSLabLogo";

function Home(props) {
  const [checkHistory, setCheckHistory] = useState([]);
  useEffect(() => {
    async function getCheckHistory() {
      let dataArray = [];
      const querySnapshot = await getDocs(collection(db, "checked-images"));
      querySnapshot.forEach((doc) => {
        let currentItem = doc.data();
        currentItem["id"] = doc.id;
        dataArray.push(currentItem);
      });
      setCheckHistory(dataArray);
    }
    getCheckHistory();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginBottom: "40px",
        }}
      >
        {" "}
        <UploadComponent
          setReport={props.setReport}
          setSearchUrl={props.setSearchUrl}
        />
        <h2 style={{ width: "50vw", margin: "auto", marginTop: "5vh" }}>
          Eerdere onderzoeken
        </h2>
        <CheckHistory checkHistory={checkHistory} />
      </div>
      <div
        style={{
          position: "fixed",
          bottom: "0px",
          backgroundColor: "white",
          width: "100vw",
          marginLeft:'-10vw'
        }}
      >
        <NWSLabLogo />
      </div>
    </>
  );
}

export default Home;
