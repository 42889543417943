import { useState } from "react";
import {
  FlexContainer,
  HeatmapGridCell,
  HeatMapImage,
  MainImage,
} from "../style";
import FooterComponent from "./FooterComponent";
import NWSLabLogo from "./NWSLabLogo";

export default function ImageRow(props) {
  const [imageFailed, setImageFailed] = useState(false);

  return (
    <>
      <HeatmapGridCell item xs={4} sm={8} md={4} lg={6}>
        {props.firstCell && (
          <MainImage alt="photoToAnalyse" src={props.firstCell} />
        )}
      </HeatmapGridCell>
      <HeatmapGridCell
        item
        xs={4}
        sm={8}
        md={4}
        lg={12}
        style={{ height: "60vh" }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {!imageFailed ? (
            <FlexContainer>
              {props.secondCell &&
                props.secondCell.map((image, i) => {
                  return (
                    <HeatMapImage
                      key={i}
                      src={image}
                      alt="heatmap"
                      onError={(e) => setImageFailed(true)}
                    />
                  );
                })}{" "}
            </FlexContainer>
          ) : (
            <p>Er ging iets mis bij het ophalen van je analyse.</p>
          )}

          <FooterComponent
            setSuspicious={(e) => props.setSuspicious(e)}
            stepper={props.stepper}
          />

        
        </div>
      </HeatmapGridCell>
    </>
  );
}
