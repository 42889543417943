import { Button, Card } from "@mui/material";
import { CheckButton, FalseButton, MainImage, TrueButton, UnclearButton } from "../style";
import { CardContent, CardMedia } from "@mui/material";
export default function MainImageReport(props) {
  return (
    <Card>
      {props.report !== undefined && (

      <CardMedia component='img' alt="photoToAnalyse" src={props.report.imageChecked} /> )}
      <CardContent>
        {props.report.metadata &&
          props.report.metadata.length > 0 &&
          props.report.metadata.map((metadataItem, index) => {
            return (
              <p key={index}>
                {metadataItem.name}: {metadataItem.value}
              </p>
            );
          })}
             {props.report.gpsReport && (
            <>
              <p>Latitude: {props.report.gpsReport.latitude}</p>
              <p>Longitude: {props.report.gpsReport.longitude}</p>
            </>
          )}

      </CardContent>

    </Card>
  );
}
