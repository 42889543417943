import ImageTextRow from "../components/ImageTextComponent";
import { CheckButton, FooterContainer } from "../style";
import { useNavigate } from "react-router-dom";
import NWSLabLogo from "../components/NWSLabLogo";

export default function GeneralInfo(props) {
  let navigate = useNavigate();

  const updateStep = () => {
    navigate("/analysis");
  };
  let text = (
    <>
      {" "}
      We zullen volgens vijf verschillende principes op zoek gaan naar
      manipulaties in je foto.
      <br />
      <br />
      Van sommige principes hebben we verschillende implementaties, die net een
      beetje anders zijn. Net als bij een menselijke analyse, kan een kleine
      aanpassing in je zoektechniek immers tot heel andere conclusies leiden.
      <br />
      <br />
      De meeste resultaten krijg je in de vorm van een heatmap. Dat is een
      afbeelding die grotendeels een effen kleur heeft, maar felle,
      contrasterende kleuren vertoont waar er volgens de check opvallende dingen
      gebeuren.
      <br /> <br />
      <b>
        Overweeg zelf of dit komt door retoucheringen, verwarrende details, of
        manipulaties met kwaad opzet.
      </b>
      <br />
      <br />
      <CheckButton onClick={updateStep}>Analyse starten</CheckButton>
    </>
  );

  return (
    <>
      <ImageTextRow firstCell={props.searchUrl} secondCell={text} />{" "}
      <div
        style={{
          position: "fixed",
          bottom: "0px",
          backgroundColor: "white",
          width: "100vw",
          marginLeft: "-10vw",
        }}
      >
        <NWSLabLogo />
      </div>
    </>
  );
}
