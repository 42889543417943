import { CheckButton, FalseButton, TrueButton, UnclearButton } from "../style";
import { Select, TextareaAutosize } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import DefaultAccordionComponent from "../components/DefaultAccordionComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import db from "../firebaseconfig";
import { updateDoc } from "firebase/firestore";
import { FieldPath, doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";

export default function ConclusionComponent(props) {
  let navigate = useNavigate();

  let [selectedValue, setSelectedValue] = useState("");
  let [explanation, setExplanation] = useState("");

  const handleChange = (conclusion) => {
    setSelectedValue(conclusion);
  };

  let params = useParams();

  useEffect(() => {

    if (props.report.conclusion){
        setSelectedValue(props.report.conclusion.imgConclusion)
        setExplanation(props.report.conclusion.explanation)
    }
   

  }, [props]);


  async function saveManipulated() {
    const reportId = params.reportId;
    const reportRef = doc(db, "checked-images", reportId);
    await updateDoc(reportRef, {
      conclusion: {
        imgConclusion: selectedValue,
        explanation: explanation,
      },
    });
    props.report["conclusion"] = {
      imgConclusion: selectedValue,
      explanation: explanation,
    };
    navigate("/")
  }

  return (
    <DefaultAccordionComponent
      title={"Besluit"}
      content={
        <>
          <FormControl>
            <p>Op basis van de volledige analyse besluit ik het volgende: </p>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="Waarschijnlijk echt"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="Waarschijnlijk echt"
                control={<Radio />}
                label="Waarschijnlijk echt"
                checked={selectedValue === "not-manipulated"}
                disabled={props.report.conclusion !== undefined}
                onChange={() => handleChange("not-manipulated")}
              />
              <FormControlLabel
                value="Waarschijnlijk vals"
                control={<Radio />}
                checked={selectedValue === "manipulated"}
                label="Waarschijnlijk vals"
                disabled={props.report.conclusion !== undefined}
                onChange={() => handleChange("manipulated")}
              />
              <FormControlLabel
                value="Onzeker"
                control={<Radio />}
                label="Onzeker"
                checked={selectedValue === "unclear"}
                onChange={() => handleChange("unclear")}
                disabled={props.report.conclusion !== undefined}
              />
            </RadioGroup>
          </FormControl>
          <TextareaAutosize
            placeholder="Toelichting"
            style={{
              width: "100%",
              marginTop: "10px",
              marginBottom: "10px",
              fontFamily: "Arial",
            }}
            minRows={3}
            onChange={(e) => {
              setExplanation(e.target.value);
            }}
            disabled={props.report.conclusion !== undefined}
            defaultValue={explanation}
          />
          {props.report.conclusion === undefined && (
            <CheckButton onClick={() => saveManipulated()}>Opslaan</CheckButton>
          )}
        </>
      }
    />
  );
}
