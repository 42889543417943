import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import ImageTextRow from "../components/ImageTextComponent";
import { useNavigate } from "react-router-dom";
import { CheckButton, FooterContainer } from "../style";
import NWSLabLogo from "../components/NWSLabLogo";

export function DetailView(props) {
  let navigate = useNavigate();

  const updateStep = () => {
    navigate("/info");
  };

  let text = (
    <>
      Een digitale analyse is niet zo verschillend van een menselijke. Zo maken{" "}
      <b>details</b> het oordeel moeilijker, en zullen{" "}
      <b>retouches, blurs, en aanpassingen in contrast</b> gemarkeerd worden als
      aanpassingen aan het origineel.
      <br /> <br />
      <b>
        Vraag je dus steeds af waarom de checks een element als verdacht
        markeren.
      </b>
      <br />
      <br />
      Denk je dat jouw foto gevoelig is voor irrelevante resultaten tijdens
      automatische checks?
      <FormGroup>
        <FormControlLabel
          control={<Checkbox />}
          onChange={(e) => {
            props.setPhotoshop(e.target.checked);
          }}
          label="Er zijn zichtbare sporen van Photoshop bewerking"
        />
        <FormControlLabel
          control={<Checkbox />}
          onChange={(e) => props.setDetail(e.target.checked)}
          label="Er staan veel details in dit deel van de foto."
        />
      </FormGroup>
      <CheckButton onClick={() => updateStep()}>Volgende</CheckButton>
    </>
  );

  return (
    <>
      <ImageTextRow firstCell={props.searchUrl} secondCell={text} />{" "}
      <div
        style={{
          position: "fixed",
          bottom: "0px",
          backgroundColor: "white",
          width: "100vw",
          marginLeft: "-10vw",
        }}
      >
        <NWSLabLogo />
      </div>
    </>
  );
}
