import { Button, Grid, Paper, StepConnector, TextField } from "@mui/material";
import styled from "styled-components";

export const UploadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50vw;
  color: white;
  margin: auto;
  margin-top: 0;
  @media (max-width: 768px) {
    width: 80vw;
  }
`;

export const UploadField = styled(TextField)`
  width: 85%;
  color: white !important;
`;

export const CheckButton = styled(Button)`
  margin-right: 5px;
  background-color: rgb(22, 40, 74) !important;
  color: white !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;


export const GrayButton = styled(Button)`
  margin-right: 5px;
  background-color: lightgray !important;
  color: white !important;
  border: none;
  border-radius: 5px;
`;


export const UnclearButton = styled(Button)`
  margin-right: 5px;
  background-color: darkgray !important;
  color: white !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const TrueButton = styled(Button)`
  background-color: green !important;
  color:white !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px !important;
`;

export const FalseButton = styled(Button)`
  background-color: red !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color:white !important;

`;

export const ColoredDot = styled.span`
  height: 15px;
  width: 15px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  display: inline-block;
`;

export const HeatmapGridCell = styled(Grid)`
  @media (min-width: 1024px) {
    height: 25vh;
    display: flex;
  }
  @media (max-width: 1024px) {
    width: 100vw;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const HeatMapImage = styled.img`
  border-radius: 5px;
  margin-right: 5px;
  margin-top: 5px;
  @media (min-width: 1024px) {
    height: 20vh;
  }
`;

export const MainImage = styled.img`
  border-radius: 5px;
  max-width: 100%;
  @media (min-width: 1024px) {
    height: 20vh;
  }
`;
export const FooterContainer = styled.div`
  text-align:center;
  @media (min-width: 1024px) {
    position: absolute;
    bottom: 15px;
    left: 50%;
  }
`;

export const LogoContainer = styled.div`
  text-align:center;
  position: absolute;
  bottom: 15px;
  left: 50%;

`;

export const QontoConnector = styled(StepConnector)(() => ({
  display: "none",
}));

export const FinalReportContainer = styled.div`
  @media (min-width: 1024px) {
    margin-left: 1em;
    padding: 2vw;
    margin-top:-2vw;
  }

  @media (max-width: 1024px) {
    margin-top: 1em;
    width:100%
  }
`;

export const MainImageReportWrapper = styled.div`

@media (min-width: 1024px) {
  height:40vh;
  width:45%;
}

@media (max-width: 1024px) {
  height:100%;
}
`

export const CenteredFlexContainer = styled.div`
  display:flex;
  justify-content:center;
`
