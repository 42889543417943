import { useLocation, useParams } from "react-router-dom";
import {
  FinalReportContainer,
  FlexContainer,
  LogoContainer,
  MainImage,
  MainImageReportWrapper,
} from "../style";
import { updateDoc } from "firebase/firestore";

import { FieldPath, doc, getDoc } from "firebase/firestore";
import db from "../firebaseconfig";
import { useEffect, useState } from "react";
import HeatMapReport from "../components/HeatMapReport";
import DefaultAccordionComponent from "../components/DefaultAccordionComponent";
import MainImageReport from "../components/MainImageReportComponent";
import ConclusionComponent from "../components/ConclusionComponent";
import NWSLabLogo from "../components/NWSLabLogo";

export default function FinalReport(props) {
  let params = useParams();
  const [report, setReport] = useState({});
  const [suspectedMaps, setSuspectedMaps] = useState([]);

  useEffect(() => {
    async function getReport() {
      const reportId = params.reportId;
      const docRef = doc(db, "checked-images", reportId);
      let docSnap = await getDoc(docRef);
      let report = docSnap.data();
      setReport(report);
      const suspectedMaps = report.suspectedMaps.filter(
        (i) => i.suspicious === "red"
      );
      setSuspectedMaps(suspectedMaps);
    }
    getReport();
  }, []);

  return (
    <>
      <FlexContainer>
        <MainImageReportWrapper>
          {" "}
          <MainImageReport report={report} />
        </MainImageReportWrapper>
        {report && report !== {} && report !== undefined && (
          <FinalReportContainer>
            <ConclusionComponent report={report} />
            {report.userInput && report.userInput.hypothesis && (
              <DefaultAccordionComponent
                title={"Eigen vooranalyse"}
                content={report.userInput.hypothesis}
              />
            )}
            {report.userInput &&
              (report.userInput.detail || report.userInput.photoshop) && (
                <DefaultAccordionComponent
                  title={"Factoren die de resultaten kunnen vertekenen"}
                  content={
                    <>
                      {" "}
                      <p>
                        Net als mensen zijn algoritmes vatbaar voor verkeerde
                        interpretaties. De volgende elementen in deze foto
                        zouden ervoor kunnen zorgen dat het algoritme iets ten
                        onrechte als verdacht beschouwt.
                      </p>{" "}
                      <ul>
                        {report.userInput.detail && (
                          <li style={{ listStylePosition: "inside" }}>
                            Er staat veel detail op het verdachte deel van de
                            foto
                          </li>
                        )}
                        {report.userInput.photoshop && (
                          <li style={{ listStylePosition: "inside" }}>
                            Er zijn duidelijke tekenen van Photoshop bewerking.
                          </li>
                        )}
                      </ul>
                    </>
                  }
                />
              )}

            {report.suspectedMaps && (
              <HeatMapReport
                title={"Heatmaps die volgens jou op manipulatie kunnen wijzen"}
                heatmaps={report.suspectedMaps.filter(
                  (i) => i.suspicious === "red"
                )}
              />
            )}

            {report.suspectedMaps && (
              <HeatMapReport
                title={"Overige heatmaps"}
                heatmaps={report.suspectedMaps.filter(
                  (i) => i.suspicious !== "red"
                )}
              />
            )}

          </FinalReportContainer>
        )}
      </FlexContainer>

    </>
  );
}
