import * as React from "react";

export default function BlockingArtifacts() {
  return (
    <>
      {" "}
      <h2>Is het patroon in de pixels doorbroken?</h2>
      <p>
        Een JPEG afbeelding wordt door je computer verwerkt in blokjes van acht
        bij acht pixels. Wanneer iemand je afbeelding bijgewerkt heeft, wordt
        dit <b>raster doorbroken</b>. Onderstaande heatmaps tonen potentiële
        onregelmatigheden in het raster.
      </p>
    </>
  );
}
