import { Grid } from "@mui/material";
import { MainImage } from "../style";

export default function ImageTextRow(props) {
  return (
    <>
      <Grid
        item
        xs={4}
        sm={8}
        md={4}
        lg={6}
        style={{ height: "25vh", display: "flex" }}
      >
        {props.firstCell && <MainImage src={props.firstCell} />}
      </Grid>
      <Grid item xs={4} sm={8} md={4} lg={12} style={{ height: "25vh" }}>
        {props.secondCell && <>{props.secondCell}</>}
      </Grid>



    </>
  );
}
