export default function ElaAndGhost() {
  return (
    <>
      <h2>Verschil in berekeningen bij recompressie</h2>
      <p>
        We hebben je afbeelding opgeslagen met een <b>lagere kwaliteit</b>.
      </p>
      <p>
        Als er een deel van je afbeelding uit een andere foto gekopieerd is,
        moet het algoritme <b>op dat vervalste gedeelte andere berekeningen</b>{" "}
        toepassen dan op de rest van de foto om tot de gevraagde kwaliteit te
        komen.
      </p>
      <p>
        Zones die met afwijkende berekeningen gecomprimeerd werden, zijn in
        onderstaande afbeeldingen gemarkeerd.
      </p>
    </>
  );
}
