export default function DoubleJpegQuantization() {
  return (
    <>
      <h2>Verschil in opbouw afbeelding</h2>
      <p>
        JPEG foto's worden opgebouwd uit vaste bouwblokken (cosinusgolven). Bij
        een normale afbeelding staan deze op dezelfde schaal. Als de afbeelding
        gemanipuleerd is, zie je echter vaak{" "}
        <b>bouwblokken op een andere schaal</b>.
      </p>
    </>
  );
}
