export default function DeepLearning() {
  return (
    <>
      <h2>Artificiële intelligentie</h2>
      <p>
        Bij deep learning laten we het algoritme een groot aantal voorbeelden
        zien van zowel authentieke als gemanipuleerde foto's. Vervolgens bepaalt{" "}
        <b>
          het algoritme zelf welke eigenschappen aantonen of een foto
          gemanipuleerd is
        </b>
      </p>
      <p>
        Die eigenschappen zijn vaak <b> wiskundig van aard</b> en dus moeilijker
        om als factchecker te beredeneren. We geven je de resultaten van deze
        algoritmes echter mee, zodat je ze ter ondersteuning van andere
        bevindingen kan gebruiken. Elk van deze algoritmes probeert op een eigen
        manier de eigenschappen van gemanipuleerde foto's te begrijpen.
      </p>
    </>
  );
}
