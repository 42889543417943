// import firebase from "firebase";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCBTUJpTkwknKaG61drm6dKtIjhi5TJ0tM",
  authDomain: "vrt-nws-lab-image-inspector.firebaseapp.com",
  projectId: "vrt-nws-lab-image-inspector",
  storageBucket: "vrt-nws-lab-image-inspector.appspot.com",
  messagingSenderId: "485679588485",
  appId: "1:485679588485:web:b59ad912de4cb04302b5ec",
  measurementId: "G-CWNCV6VW8C"
};

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp)
  
export default db;